@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
body{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
body{
  font-family: 'Inter', sans-serif;
  font-weight: 400;
} */
ul {
  list-style-type: none;
}

table, th, td {
  
  border-collapse: collapse;
}
th, td {
  padding: 5px;
  text-align: left;
}
.css-ktslay-MuiButtonBase-root-MuiIconButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative !important;
  
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 0 10px;
  position: relative;
  background-color: #494949;
  top: calc(50% - 20px)!important;
  color: white;
  font-size: 30px;
  -webkit-transition: 200ms;
  /* transition: 200ms; */
  cursor: pointer;
  /* opacity: 0; */
  opacity: 100% !important;
}
.css-e8vs8u {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  margin: 0px 10px;
  position: relative;
  
  background-color: rgb(73, 73, 73);
  color: white;
  font-size: 35px;
  transition: all 200ms ease 0s;
  cursor: pointer;
  /* opacity: 0; */
  opacity: 100% !important;
  top: calc(50% - 20px) !important;
}
.kVtMtA {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 96px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: -30px !important;
  right: initial;
}
.bhRnqO {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #0009;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: initial;
  right: -30px !important;
}

