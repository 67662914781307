.brandslider p {
  font-size: 14px;
  color: #00000066;
}
.brandslider h1 {
  font-size: 42px;
}
.brandslider_right p{
    font-size: 16px;
    line-height: 24px;
    justify-content: right;
   
}
.brandslider_div{
  width:80%
}
.brandslider_right span{
   font-weight: bold;
    color:#32bec0
    
}
.brandcarausal{
    justify-content: center;
     text-align: center;
   margin-top: 30px;
}
.brandcarausal p{
   margin:15px;
    font-size:14px;
}
.getstarted{
    padding:10px;
    font-size: 20px;
    font-weight: bold;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .brandslider_right span{
    font-weight: bold;
     color:#32bec0;
     text-align: center;
    
 }
 .brandslider_div{
  width:100%
}
}

@media screen and (max-width: 767px)
{
.brandslider_div{
  width:100%
}
}