.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-daterange-menu.rs-picker-menu {
    left: auto !important;
    right: 10% !important;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #22b7b7 !important;
}
.rs-btn-link {
    color: #1675e0;
    color: var(--rs-btn-link-text);
    background-color: #22b7b7;
    border: none;
}
.rs-btn-link {
    color: white !important;
    background-color: #22b7b7 !important;
}
.rs-btn-sm {
    font-size: 12px !important;
    padding: 3px 8px !important;
    margin: 5px;
}