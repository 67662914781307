.section_title{
    font-size: 40px; 
    color: #0c4c81;
    font-weight: bold;
}

.section_title span{
    font-size: 40px; 
    color: #22bbb9;
}
.section_prg p{
    font-size: 14px;
    line-height:22px;
    color: #76766b;
}



/* / mobile / */
@media screen and (min-width: 320px) and (max-width: 480px){
    .section_title{
        font-size: 25px; 
        text-align: center;
        margin: 15px;
       
    }
    
    .section_title span{
        font-size: 25px; 
    }
}


/* ipad */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .section_title{
        font-size: 25px; 
        text-align: center;
        margin: 15px;
       
    }
    
    .section_title span{
        font-size: 25px; 
    }
}




