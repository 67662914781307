.privacy-policy h2{
    margin-bottom: 10px;
    margin-top: 10px;
}
.privacy_head span{
    font-size: 42px;
}


@media screen and (min-width: 320px) and (max-width: 480px) {
    .privacy-policy p{
       text-align: left;
    }
    .privacy-policy h2{
        margin-left: 28px;
        font-size: 16px;
    }
}