.content {
    max-width: 1280px;
    overflow-x: hidden;
   padding: 0px;
    background-color: #F4F6F6;
  }
  
  .card {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 24rem;
    position: relative;
   border: none;
    padding: 0px 20px 0px 20px;
    background-color: #F4F6F6;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-header span {
    color: #2d3748;
  }
  
  .card-image {
    width: 100%;
    height: 14rem;
    max-width: 100%;
    object-fit: cover;
  }
  
  .card button {
    display: inline-block;
   
    color: #fff;
    border: none;
    padding: 1.5em 1.6em;
    cursor: pointer;
    text-decoration: none;
    font-size: 60px;
    font-family: inherit;
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: background-color 0.5s ease-out;
  }
  
  .card button:focus {
    outline: none;
  }
  
  .card button:hover {
   
  }
  
  .text-info {
    height:500px;
    text-align: center;
    border:1px solid #10618C;
    background-color: #fff;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    position:relative;
    top:300px;
    z-index: 100;
    margin-bottom: 1.5rem;
  }
  
  .controls button {
    /* width: 40px;
    height: 40px; */
    border: 0;
   font-size: 60px;
    color: gray;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
  }
  .brand_margin{
    position: relative;
    top: 30px;
  }


  @media screen and (min-width: 320px) and (max-width: 480px)
  {
    .controls button {
        /* width: 40px;
        height: 40px; */
        border: 0;
       font-size: 40px;
        color: gray;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.5s ease-out;
      }
      /* .slick-slide.slick-cloned {
        display: block !important;
    } */
  }
   
  
  
  