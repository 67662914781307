.hasslefree{
    justify-content: center;
}
.hasslefree h1{
    font-size: 14px;
    color:#777777;
    line-height: 30px;
 }
.hasslefree h2{
    font-size: 42px;
   
 }
 .hasslefree p{
    font-size: 16px;
    margin-top: 25px;
   
 }
 .hasslefree span{
   
    color:#32bec0
    
}