.workshopone{
    justify-content: center;
}
.workshopone p{
    font-size: 14px;
    color:#777777;
    line-height: 30px;
}
.workshopone_right {
  text-align: right;
}
.workshopone_right h1{
    font-size: 14px;
    color:#00000066
  }
  .workshopone_right h2{
    font-size: 42px;
    color:#000000;
  } 