a {
    text-decoration: none !important;
}

.header-admin .navbar {
    background-color: #fff;
    border-bottom: 1px solid #707070;

}

.css-r6z5ec {
    z-index: 99999 !important;
}

.header-admin .navbar-light .navbar-nav .nav-link {
    color: #000000;
    font-weight: 700;
    font-size: 24px;
    padding: 25px;
    position: relative;

}

.faq-box strong {
    font-size: 24px;
    font-weight: 700;
    color: "black";

}

.header-admin .navbar-light .navbar-nav .nav-link.active::after {
    content: '';
    /* background-color: #10618C; */
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.header-admin .login,
header.header-admin .login a {
    color: #000000;
}

.Wizard-navigation {
    -webkit-box-shadow: 3px 0px 20px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 3px 0px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 3px 0px 20px 0px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 30px;
    margin-top: 30px;
    border-radius: 15px;
    height: 702px;
    position: relative;
}

.Wizard-navigation::after {
    content: '';
    background-image: url(../assets/img/lady_in_car.png);
    max-width: 263px;
    max-height: 380px;
    height: 100vh;
    width: 100vw;
    position: absolute;
    bottom: 0;
    right: 0;
    background-size: cover;
    z-index: 0;
}

.Wizard-navigation {
    padding: 0;
    list-style: none;
}

.Wizard-navigation a {
    text-decoration: none;
    color: #000000;
}

.Wizard-navigation .nav-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.Wizard-navigation .nav-box img {
    max-width: 65px;
}

.Wizard-navigation .nav-box .nav-content {
    padding-left: 30px;
}

.Wizard-navigation .nav-box .nav-content h5 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0;
}

.Wizard-navigation .nav-box .nav-content p {
    margin-bottom: 0;
}

.Wizard-navigation .nav-box .icon-box {
    border: #0C4C82 3px solid;
    border-radius: 15px;
    color: #22BBB9;
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.Wizard-navigation a {
    opacity: 50%;
}

/* .Wizard-navigation a.active {
    opacity: 1;
} */

.Wizard-navigation .nav-link:focus,
.Wizard-navigation .nav-link:hover {
    color: black;
    text-decoration: none;
}

.payment-box {
    margin-top: 30px;
    padding: 0 100px;
}

.payment-box h1 {
    font-size: 24px;
    font-weight: 700;
}

.ipay {
    max-height: 34px;
}

.quot {
    padding: 25px 0;
}

.payment-box .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #32BEC0;
    border-color: #32BEC0;
}

.payment-box .nav-tabs .nav-link {
    color: #000000;
    border: 1px solid #000000;
    border-radius: 0;
    font-weight: 700;
    font-size: 18px;
}

.payment-box .nav-tabs {
    border: none;
    display: flex;
    justify-content: space-between;
}

.payment-box .list-group-item {
    border: 1px solid #CED4DA;
    transition: all 0.5s;
    margin-bottom: 15px;
}

.payment-box .list-group-item:hover {
    border-color: #F05A28;
}

.list-group-item label {
    display: flex;
    align-items: center;
}

.list-group-item label img {
    margin-right: 15px;
}

.list-group-item .form-check-input:checked {
    background-color: #F05A28;
    border-color: #F05A28;
}

.home-nav {
    background: -webkit-linear-gradient(left, rgb(12, 78, 131) 0%, rgb(21, 121, 152) 40%, rgb(29, 164, 173) 80%, rgb(34, 185, 184) 100%);
    padding: 25px 0;
}

.home-nav .nav-item {
    background-color: transparent;
}

.home-nav .nav-item a.nav-link {
    padding: 0 25px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
}

.home-nav .nav-item a.nav-link.active {
    position: relative;
    color: #fff;

}


.home-nav .nav-item a.nav-link.active::after {
    content: '';
    background-color: #fff;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -25px;
    left: 0;
}

.overview-box {
    border: 1px solid #E4E8EB;
    border-radius: 15px;
    padding: 20px;
    margin-top: 20px;
}

.overview-box .overview-item {
    margin-top: 20px;
    border: 1px solid #E4E8EB;
    height: 206px;
    border-radius: 5px;
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.overview-box .overview-item h1 {
    font-weight: 700;
    font-size: 30px;
}

.overview-box .overview-item span {
    font-size: 16px;
}

.overview-box .overview-item p {
    font-size: 16px;
    color: #000000;
}

.overview-box .overview-item .expired h1,
.overview-box .overview-item .expired span {
    color: #DF5C5C;
}

.overview-box .overview-item .unpaid h1,
.overview-box .overview-item .unpaid span {
    color: #426AF7;
}

.overview-box .overview-item .paid h1,
.overview-box .overview-item .paid span {
    color: rgb(23, 190, 187);
}

.overview-box .overview-item .draft h1,
.overview-box .overview-item .draft span {
    color: #9B9B9B;
}

.dashbord-box {
    background-color: #F3F3F3;
    padding: 25px;
    margin-top: 20px;
    border-radius: 5px;
}

.carousel-indicators [data-bs-target] {
    background-color: #7E7E7E;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    border: none;
}

.carousel-indicators .active {
    background-color: #32BEC0;
    width: 16px;
    height: 6px;
    border-radius: 50px;
}

.carousel-indicators {
    bottom: -25px;
}

.history thead {
    background-color: #10618C;
    color: #fff;
    border: transparent;
}

.history.table>:not(:first-child),
.history.table td,
.history.table tr {
    border: none !important;
}

.history tr td .btn {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    width: 58px;
    margin: 0 auto;
    text-decoration: none;
}

.history tr td .btn-success {
    background-color: #1E9A8B;
}

.history tr td .btn-danger {
    background-color: #DF5C5C;
}

.history tr td .btn-warning {
    background-color: #426AF7;
    border-color: #426AF7;
    color: #fff;
}

.history tr td .btn-info {
    background-color: #9B9B9B;
    border-color: #9B9B9B;
    color: #fff;
}

.page-item .page-link {
    color: #000000;
    border: none;
    border-radius: 5px;
}

.page-item.active .page-link {
    background-color: #1E9A8B;
    border-color: #1E9A8B;
}

.dealer-overview {
    border: 1px solid #E4E8EB;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
}

.dealer-overview span {
    font-size: 20px;
    color: #000000;
}

.dealer-overview h1 {
    color: #1FACB1;
    font-size: 30px;
    font-weight: 700;
}

.datepicker .dropdown-item {
    padding: 0;
}

.pay-block{
    border: 1px solid #421f1f29;
    padding: 10px 15px 4px;
    margin-top: 10px !important;
}

/* 18-april */
.continental-car {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.continental-car span {
  right: 0px;
  position: absolute;
}
.continental-car .tooltip {
  font-size: 12px;
  background: red;
}
.tooltip-inner{
  font-size: 12px;
  background: #0c4c81;
  text-align: inherit;
  max-width: 330px;
  border-radius: 30px;
  padding: 20px;
  padding-top: 10px;
}
.tooltip-inner::after {
  position: relative;
  height: 10px;
  width: 10px;
  display: block;
}
.tooltip-arrow{
  left: 0px !important;
  height: 40px !important;
  width: 36px !important;
  background: #0c4c81;
  bottom: 6px !important;
}
.tooltip-arrow::before{
  bottom: -6px !important;
  right: 0px !important;
  top: unset !important;
}
.continental-car h3{
  color:#0c4c81;
  font-size: 14px;
  font-weight: bold;
}
.car-card{
  background-color: #b7b9be45;
  border-radius: 10px;
  padding: 8px;
  margin-top: 20px;
}
.car-card label.form-label {
  max-width: 484px;
}
.car-details ul{
  padding-left: 20px;
}
.car-details ul li{
  color:#3b3b3b;
  font-size: 12px;
  font-weight: normal;
}
.car-price{
  text-align: right;
}
.car-price h3{
  color:#0c4c81;
  font-size: 19px;
  font-weight: normal;
}
.car-price h3 span{
  color:#0c4c81;
  font-size: 8px;
  font-weight: normal;
}
.car-card .checkbox{
  align-items: center;
  display: flex;
  margin-right: 10px;
}
.car-card .checkbox input{
  padding: 0.5em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0.1em solid black;
  outline-offset: 0.1em;
  border-radius: 5px !important;
}

/* 18-april-end */

@media only screen and (max-width: 1024px) {

    .authentication-box img.mobile-logo,
    .authentication-box_otp img.mobile-logo {
        position: relative;
        margin-bottom: 50px;
        max-width: unset;
        width: 100%;
        bottom: unset;
    }

    .authentication-box,
    .authentication-box_otp {
        margin-top: 45px;
    }

    .authentication-box_otp .authentication-inputs {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .Wizard-navigation::after {
        background: none;
    }

    .Wizard-navigation {
        display: none;
    }

    .payment-box {
        padding: 0;
    }

    .payment-box h1 {
        text-align: center;
    }

}

@media screen and (max-width: 320px) {
    .cust-form-font{
        font-size: 14px;
    }
    .custom-resp {
        font-size: 12px;
        right: 5px !important;
        top: 7px !important;
    }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .responsive-tablet {
        width: 270px;
    }

}