/* .carousel-control-next-icon:after
{
  content: '>';
  font-size: 40px;
  color: #1a9ca9;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 40px;
  color: #1a9ca9;
} */

.carousel-control-prev-icon {
  display: none;
}

.carousel-control-next-icon {
  display: none;
}

.navbar_section {
  position: relative;
  left: 50px;
  width: 600px;
}

header .navbar {
  /* background-color: rgba(0, 0, 0, 0.2); */
}

.desk_logo {
  display: none;
}

.login_mobile {
  display: none
}

header .navbar-brand img {
  height: 80px;
}

.privacy-policy h2 {
  font-size: 24px;
  font-weight: bold;
}

.privacy-policy p {
  font-size: 16px;
}

header .navbar-light .navbar-nav .nav-link {
  font-weight: 700;
  font-style: normal;
  color: #000000;
  text-decoration: none;
}

header .login {
  color: #000000;
  line-height: 27px;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  display: flex;
}

header .login i {
  font-size: 24px;
}

header .login a {
  color: #000000;
  padding: 0 5px;
  font-size: 19.4649px;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none;
}

.dropdown-toggle::after {
  color: #000000;
}

.banner {
  background-image: url(../img/banner_bg.png);
  /* height: 100vh; */
  width: 100%;
  background-size: cover;
  position: relative;
}

.banner .banner-content h1 {
  font-size: 71.9652px;
  color: rgb(255, 255, 255);
  line-height: 86px;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  font-weight: 700;
}

.logo_image {
  position: relative;
  right: 5px
}

.banner_form {
  border: "1px solid red";
}

.banner .banner-content p {
  font-size: 18.6578px;
  color: rgb(255, 255, 255);
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
}

.banner img {
  position: relative;
  bottom: -75px;
}

.banner-quote {
  background-color: #fff;
  padding: 25px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.btn-primary {
  background-color: #edf0f3;
  font-weight: 700;
  font-style: normal;
  color: rgb(23, 190, 187);
  text-decoration: none;
  border: none;
  border-radius: 15px;
  padding: 15px;
  font-size: 20px;
  transition: all 0.5s;
}

.btn-primary:hover {
  color: #edf0f3;
  background-color: rgb(23, 190, 187);
}

.section-title {
  text-align: center;

}

.usericon {
  position: relative;
  left: 25px
}

.usericonbm {
  position: relative;
  left: 25px
}

.section-title span {
  color: #00000066;
  font-size: 16px;
  font-weight: 700;
}

.section-title-annun {
  text-align: center;
  display: inline-flex;
}

.section-title-annun span {
  color: #00000066;

  font-weight: 700;
}

.navbar_section {}

.dropdown-menu[data-bs-popper] {

  margin-top: 0.125rem;
  top: 100%;
  left: -100px;
}

.navbar-collapse {
  flex-basis: 100%;
  /* flex-grow: 1; */
  flex-grow: 0 !important;
  align-items: center;
}

.section-title h2 {
  font-size: 42px;
  color: rgb(23, 190, 187);
  font-weight: 700;
}

.section-title h2 span {
  color: black;
  font-size: 42px;
}

.section-title.light span {
  color: #fff;
}

.section-title.light h2 {
  color: rgb(34, 187, 185);
}

.section-title.light h2 span {
  color: #fff;
}

.box-content {
  padding: 15px;
}

.box-content p {
  color: rgb(12, 76, 130);
}

.autogen {
  background-image: url(../img/horizontal_gradient_background.png);
  position: relative;
}

.autogen::after {
  content: "";
  background-image: url(../img/eautogen_flaticon.png);
  height: 900px;
  width: 900px;
  position: absolute;
  top: 50px;
  right: 0;
  opacity: 0.1;
}

.enbm {
  display: flex;
  width: 150px;
  text-align: center;
  justify-content: center;
  font-weight: "bold";
}

.autogen strong {
  font-size: 19px;
  color: #fff;
}

.autogen p {
  color: #fff;
  font-size: 12px;
}

.autogen .icon-box img {
  max-height: 82px;
}

.autogen .icon-box {
  display: flex;
  align-items: center;
  max-width: 350px;
  padding: 15px 0;
}

.autogen .icon-box .icon-content {
  padding-left: 50px;
}

.autogen .section-img {
  position: absolute;
  right: 0;
  bottom: 50px;
  max-height: 750px;
  width: 50%;
  object-fit: contain;
  z-index: 9;
  object-position: right;
  padding: 0;
}

.how_work {
  box-shadow: 0 2px 8px rgb(14 19 24 / 7%);
  margin-top: 150px;
}

.how_work strong {
  font-size: 14px;
  text-align: center;
  justify-content: center;
}

.how_work p {
  font-size: 16px;
  text-align: center;
}

.workshops::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0%;
  z-index: -1;
}

.workshops .workshop-box {
  border: 1px solid #000;
  position: relative;
  z-index: 1;
  margin: 20px;
  height: 471px;
  background-color: #fff;
  padding: 10px;
  /* padding-bottom: 45px; */
  text-align: center;
}

footer {
  background-image: url(../img/banner_bg.png);
  /* height: 100vh; */
  width: 100%;
  background-size: cover;
  position: relative;
}

.faq .faq-box {
  border: 1px solid #fff;
  padding: 25px;
  color: #fff;
}

footer img {
  max-width: 250px;
  margin: auto;
  display: flex;
}

footer {
  color: #fff;
}

footer p {
  display: flex;
  align-items: flex-start;

}

footer p i {
  padding-right: 25px;
}

footer ul {
  padding: 0;
  list-style: none;
}

footer ul li a.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  opacity: 60%;
}

footer ul li a.nav-link strong {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  opacity: 100%;
}

footer .footer-bottom {
  border-top: 1px solid #fff;
  padding: 15px 0;
  text-align: center;
  display: flex;
}

.footer-bottom p {
  font-size: 10px;
  color: #fff;
  font-weight: bold;
}

footer .footer-bottom p {
  margin: 0 auto;
}

.quot,
.customer_details {
  padding: 25px;
}

.autogen.whatincluded {
  background: none;
}

.autogen.whatincluded strong,
.autogen.whatincluded p {
  color: #000;
}

.autogen.whatincluded strong span {
  color: rgb(34, 187, 185);
}

.box-gray {
  border: 1px solid rgb(245, 246, 248);
}

.box-gray .box-head {
  background-color: rgb(245, 246, 248);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
}

.ipay88 {
  background-color: rgb(253, 100, 4);
  color: #fff;
  border-radius: 5px;
}

.box-gray .box-content {
  padding: 25px;
}

.box-gray .box-content .payment_methods {
  border: 1px solid rgb(245, 246, 248);
  padding: 15px;
}

.box-gray .box-content .payment_methods p {
  border-bottom: 1px solid rgb(245, 246, 248);
}

.box-gray .box-content .payment_methods img {
  max-height: 25px;
}

.authentication-content {
  background-image: url(../img//banner_bg.png);
  height: 130vh;
  width: 100%;
  background-size: cover;
  background-position: right;
  display: flex;
  padding: 0 80px;
  position: relative;
}

.authentication-content h1 {
  font-size: 48px;
  color: #fff;
  font-weight: 700;
}

.authentication-content h1 span {
  color: #084b83;
  display: flex;
}

.authentication-content p {
  color: #fff;
}

.authentication-content img {
  width: 230px;
  position: absolute;
  top: 80px;
}

.faqsection h2 {
  font-size: 42px;
  color: black;
  font-weight: 700;
  text-align: center;
}

.faqsection span {
  font-size: 32px;
  color: black;
  font-weight: 700;
  text-align: center;
}

.faqsection strong {
  font-size: 20px;
  color: black;
  font-weight: 700;
  text-align: left;
}

.faqsection p {
  font-size: 18px;
  color: black;

  text-align: left;
}

.authentication-box {
  max-width: 855px;
  display: block;
  margin: 0 auto;
  position: relative;
  height: 70vh;
  /* display: flex;
  align-items: center; */
  margin-top: 90px;
}

.authentication-box_otp {
  max-width: 855px;
  display: block;
  margin: 0 auto;
  position: relative;

  display: flex;
  align-items: center;
}

.authentication-box_otp .authentication-inputs {
  width: 100%;
  margin-bottom: 15%;
}

.authentication-box_otp h1 {
  font-size: 24px;
  font-weight: 700;
}

.authentication-box_otp p a {
  font-weight: 700;
  color: #32bec0;
  text-decoration: none;
}

.authentication-box_otp img {
  max-width: 370px;
  margin: 0 auto;
}

.authentication-box .authentication-inputs {
  width: 100%;
  margin-bottom: 15%;
}

.authentication-box h1 {
  font-size: 24px;
  font-weight: 700;
}

.authentication-box p a {
  font-weight: 700;
  color: #32bec0;
  text-decoration: none;
}

.authentication-box img {
  max-width: 370px;
  margin: 0 auto;
}

.btn-secondary {
  background-color: #32bec0;
  border-color: #32bec0;
  text-transform: uppercase;
  transition: all 0.5s;
}

.btn-secondary:hover {
  background-color: #29989a;
  border-color: #29989a;
}

.btn-outline-secondary {
  border-color: #32bec0;
}

.btn-outline-secondary:hover {
  background-color: #29989a;
  border-color: #29989a;
}

.claim_img {
  display: none;
}

.banner_form {
  padding: 20px;
  position: absolute;
  bottom: -150px;
  background-color: #f4f6f6;
}

.btn-outline-secondary:hover {
  background-color: #29989a;
  border-color: #29989a;
}

.btn {
  transition: all 0.5s;
}

.btn-danger {
  background-color: #df5c5c;
  border-color: #df5c5c;
}

.btn-danger:hover {
  background-color: #c33737;
  border-color: #c33737;
}

.text-danger {
  color: #df5c5c;
}

.hero-bg {
  background-image: url(../img/herobg.png);
  /* height: 100vh; */
  width: 100%;
  background-size: cover;
  position: relative;
  margin: 0 auto;
  height: 100vh;
  max-width: 1600px;
  max-height: 700px;
  display: flex;
  background-position: center;
  align-items: center;
}

.mobile_bg {
  background-image: url(../img/mobilecarview.png);
  /* height: 100vh; */
  width: 100%;
  background-size: cover;
  position: relative;
  margin: 0 auto;
  height: 100vh;
  max-width: 1600px;
  max-height: 700px;
  background: none;
  background-position: center;
  align-items: center;
}

.hiw_img {
  max-width: 96px;
  display: flex;
  margin: 0 auto;
}

/* revpay css */
.payment_box.payment_method_revpay {
  background-color: #efefef;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  font-size: .92em;
  border-radius: 2px;
  line-height: 1.5;
  color: #515151;
}

.pay-method {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.method-wrap {
  margin: 10px 20px;
  display: flex;
  align-items: center;
}

.form-row.form-row-wide input {
  cursor: pointer;
  margin: 0 1em 0 0;
}

.form-row.form-row-wide img {
  margin-left: 10px;
}

span.required {
  color: red;
  font-weight: 700;
}

.form-row.form-row-wide label {
  line-height: 2;
  font-weight: 700;
  font-size: .9rem;
  color: #515151;
  letter-spacing: 1px;
  margin-bottom: 20px;
}


/* mobile view */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .desk_logo {
    display: none;
    height: 40px;
  }

  .banner .banner-content h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .mobile_bg {
    display: none;
  }
}

@media screen and (min-device-width: 540px) and (max-device-width: 720px) {
  .desk_logo {
    display: none;
    height: 40px;
  }

  header .navbar-brand img {
    height: 55px;
  }

  .banner .banner-content h1 {
    font-size: calc(20px);
    margin: 0 auto;
  }

  header .login {
    display: flex;
    color: black;
  }

  header .login a {
    color: black;
  }

  .login_mobile {
    display: block;
  }

  .banner {
    background-position: right;
  }

  .autogen .section-img {
    display: none;
  }

  .banner img {
    bottom: -35px;
  }

  .banner-content {
    margin-bottom: -100px;
  }

  .logo {
    display: none;
  }

  .desk_logo {
    display: block;
    height: 40px;
  }

  header .navbar {
    background-color: white;
  }

  header .navbar-light .navbar-nav .nav-link {
    color: black;
  }

  i.fa-regular.fa-circle-user {
    color: black !important;
  }

  .section-title h2 {
    font-size: 30px;
    text-align: center;
  }

  .section-title h2 span {
    font-size: 30px;
    text-align: center;
  }

  .arrow_img {
    display: none;
  }

  .hiw_img {
    height: 176px;
    width: 176px;
    margin: auto;
    justify-content: center;
    display: flex;
  }

  .how_work strong {
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin: auto;
    justify-content: center;
    font-weight: bold;
  }

  .how_work p {
    width: 80%;
    margin: auto;
  }

  .faq,
  footer {
    background-position: right;
  }

  .faq .faq-box {
    border: none;
  }

  .claim_img {
    display: block;
  }

  .desk_claim_img {
    display: none;
  }

  /* quotaion page  */
  .autogen .icon-box {
    display: grid;
  }

  .banner_form {
    position: relative;
    bottom: 0;
    top: -85px;
  }

  .how_work {
    margin-top: 0;
  }

  .mobile_bg {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  header .navbar-brand img {
    height: 40px;
  }

  .banner .banner-content h1 {
    font-size: calc(20px);
    margin: 0 auto;
  }

  header .login {
    display: flex;
    color: black;
  }

  header .login a {
    color: black;
  }

  .login_mobile {
    display: block;
  }

  .banner {
    background-position: right;
  }

  .autogen .section-img {
    display: none;
  }

  .banner img {
    bottom: -35px;
  }

  .banner-content {
    margin-bottom: -100px;
  }

  .logo {
    display: none;
  }

  .desk_logo {
    display: block;
    height: 40px;
  }

  header .navbar {
    background-color: white;
  }

  header .navbar-light .navbar-nav .nav-link {
    color: black;
  }

  i.fa-regular.fa-circle-user {
    color: black !important;
  }

  .section-title h2 {
    font-size: 30px;
    text-align: center;
  }

  .section-title h2 span {
    font-size: 26px;
    text-align: center;
  }

  .arrow_img {
    display: none;
  }

  .hiw_img {
    margin: auto;
    justify-content: center;
    display: flex;
  }

  .how_work {
    margin-top: -55px;
  }

  .how_work strong {
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin: auto;
    justify-content: center;
    font-weight: bold;
  }

  .how_work p {
    width: 80%;
    margin: auto;
  }

  .faq,
  footer {
    background-position: right;
  }

  .faq .faq-box {
    border: none;
  }

  .claim_img {
    display: block;
  }

  .desk_claim_img {
    display: none;
  }

  /* quotaion page  */
  .autogen .icon-box {
    display: grid;
  }

  .autogen .icon-box .icon-content {
    padding-left: 10px;
  }

  .include_auto {
    width: 85%;
    margin: 0 auto;
  }

  /* login */
  .btn-secondary {
    background-color: #32bec0;
    border-color: #32bec0;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .btn-secondary:hover {
    background-color: #29989a;
    border-color: #29989a;
  }

  .banner_form {
    position: relative;
    bottom: 0;
    top: -85px;
  }

  .how_work {
    margin-top: -55px;
  }

  .mobile_bg {
    background: url(../img//mobilecarview.png) no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hero_img {
    padding-top: 15px;
  }

  .herodesk {
    padding-bottom: 500px;
  }

  .hero-bg {
    background: none;
  }

  .section-title h3 {
    margin-left: auto;
    margin-right: auto;
  }

  .periodic-left h2,
  p {
    text-align: center;
  }

  .girlpara h1 {
    text-align: center;
    line-height: 35px;
  }

  .brandcarausal p {
    padding-top: 30px;
  }

  .logo_image {
    position: relative;
    right: 0px;
    margin-right: -50px;
  }

  .mobile_center {
    text-align: center;
    justify-content: center;
  }

  .enginedrop h3 {
    text-align: center;
    line-height: 65px;
  }

  .periodic-left h1 {
    text-align: center;

  }

  .lubricant {
    position: relative;
    bottom: 25px
  }

  .getstarted {
    margin-top: 15px;
  }

  .getstarted {
    margin-top: 15px;
  }

  .navbar_section {
    margin-left: 10px;
    left: 0px;
    width: auto;
  }


  .image_content {

    text-align: center;
    position: relative;
    left: 35%
  }

  .controls button {
    /* width: 40px;
    height: 40px; */
    border: 0;
    font-size: 40px;
    color: gray;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
  }

  .enbm {
    display: flex;
    width: 200px;
    text-align: center;
    justify-content: right !important;
    font-weight: "bold";
    position: relative;
    right: 47px;

  }

  .car_image {
    display: none;
  }

  .usericon {
    position: relative;
    left: -5px;
    /* top:20px; */
  }

  .usericonbm {
    position: relative;
    left: -5px;
  }

  .banner-btn {
    position: absolute;
    z-index: 9999;
    top: 75%;
    left: 19% !important;
    transform: translate(-50%, -50%);
    font-size: 10px;
  }
}