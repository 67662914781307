.periodic-left {
  border: "1px solid #e6e6e6";
  text-align: right;
}
.periodic-left h1 {
  font-size: 16px;
  color: #00000066;
  line-height: 25px;
  font-weight: bold;
}
.periodic-left h2 {
  font-size: 42px;
  color: #000000;
  font-weight: bold;
}
.periodic-left h3 {
    font-size: 14px;
    color: #000000;
  }
