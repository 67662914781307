.checkout_right h1{
    font-size:16px;
   font-weight: normal;;
}
.checkout_right h2{
    font-size:12px;
    font-weight: normal;;
}
.checkout_right_coupon{
    background-color: #1FB7B4;
    padding: 5%;
    border-radius: 35px;
    width:60%
}
.checkout_right_coupon h1{
    font-size:16px;
    font-weight: bold;;
}
.checkout_right_coupon h2{
    font-size:15px;
    font-weight: normal;
}
.checkout_right_coupon span{
    font-size:40px;
    font-weight: normal;
}

.checkout_title{
    font-size: 34px;
    color: #0C4C82;
  }
  .ckeckout_bg_slider{
    background-image:linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url("../../../src/assets/img/locate_us_bg.png");
    width: 100%;
    /* opacity: 50%; */
    height: 750px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }


@media screen and (min-width: 320px) and (max-width: 480px) {

.checkout_right_coupon span {
    font-size: 16px;
  }
  .checkout_right_coupon h1{
    font-size: 12px;
  }
  .checkout_right_coupon h2{
    font-size: 12px;
  }
  .checkout_title{
    font-size: 20px;
  }
  .section-title span{
    font-size: 12px;
  }
  .checkout_right_coupon{
    margin-left: 10px;
  }

}