.terms h1{
    font-size: 24px;
    color: black;
  
    font-weight: bold;
    margin: 10px 0px 10px 30px;
}
.term_head{
    font-size: 42px;
    font-weight: bold;
    margin-top: 25px;
}
    
.ul_wrapp{
    list-style-type: disc;
    padding-left: 2rem !important;
}


@media screen and (min-width: 320px) and (max-width: 480px) {
    .terms p{
       text-align: left;
    }
    .term_head{
        font-size: 24px;
    }
    .terms h1{
        font-size: 16px;
    }
}
