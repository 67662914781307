.refundpolicy h1{
    font-size: 24px;
    color: black;
    text-decoration: underline;
    font-weight: bold;
    margin: 10px 0px 30px 0px;
}

.refundpolicy h2{
    font-size: 16px;
    color: black;
   
    font-weight: bold;
    margin: 30px 0px 10px 20px;
}

.refundpolicy p{
    font-size: 16px;
    color: black;
   
    
    margin: 10px 0px 10px 0px;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
    .refundpolicy p{
       text-align: left;
    }
}
